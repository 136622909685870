import React from "react";

import '../css/tickets.css'

var MAYOT = 'https://sochi.qtickets.events/132294-og-buda-26-oktyabrya?utm_source=trap&utm_medium=trap&utm_campaign=trap&utm_content=trap&utm_term=trap'
var BBT = 'https://trap-house.qtickets.ru/event/133350'
var FEST = 'https://sochi.qtickets.events/129411-south-festival-4-artista-predprodazha'

function Button(props) {
  return(
    <a target={"_blank"} rel="noreferrer" href={props.link} className="ticketBtn">{props.text}</a>
  );
}

function Tickets() {
  return(
    <div className="containerTickets" id="ticket">
      <h1 className="ticketsToBuy">Чтобы купить билет</h1>
      <h1 className="ticketsChoise">Выбери мероприятие</h1>
      <div className="ticketsContainer">
        <Button link={MAYOT} text="OG BUDA"></Button>
        <Button link={BBT} text="HALLOWEEN"></Button>
        <Button link={FEST} text="FESTIVAL"></Button>
      </div>
      <p>*Если кнопка не работает, то билеты приобретаются на входе</p>
    </div>
  )
}

export default Tickets;